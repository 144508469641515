import axios from 'axios';

/* BASEURL */
export const baseURL = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  // withCredentials: true
});

/* ACTIONS */
export const ACTION = {
  AUTH_SET_JWTTOKEN: '/api/v1/authenticate',
  STRIPE_CHECKOUT: '/api/v1/stripe_pay',
  STRIPE_CHECKOUT_RENEW: '/api/v1/stripe_renew',
  STRIPE_CHECKOUT_UPDATEPRICE: '/api/v1/stripe_update_price',
  PAYMENT_DETAILS_CHANGE: '/api/v1/stripe_edit_card',
  CHANGE_STRIPE_QUANTITY: '/api/v1/stripe_update_qty',
  GET_CONTACTS_WITHOUT_GENDER: '/api/v1/payments/input',
  DELETE_SUBSCRIPTION: '/api/v1/subscriptions',
  RESTORE_SUBSCRIPTION: '/api/v1/subscriptions',
  SETTINGS: '/api/v1/settings',
  INVOICES: '/api/v1/invoices',
};

// -------------- Twilio ----------------
const baseUrlTwilio = process.env.REACT_APP_API_URL_TWILIO; // TODO: In PROD : process.env.REACT_APP_API_URL_TWILIO; DEV : 'http://localhost:4000'

export const apiTwilio = axios.create({
  baseURL: baseUrlTwilio,
  withCredentials: true,
});
// Action twilio
export const ACTION_TWILIO = {
  CUSTOM_OBJECTS: `/api/hsapp/iframe/properties`,
  ACCOUNT: `/api/hsapp/iframe/account`,
  OVERRIDE_SETTINGS: '/api/hsapp/iframe/update',
  SETTINGS: `/api/hsapp/iframe/settings`,
  USER_MAP: `/api/hsapp/iframe/users`,
  PROPS_COMPANIES: '/api/hsapp/iframe/properties/companies',
  PROPS_CONTACTS: '/api/hsapp/iframe/properties/contacts',
  PROPS_DEALS: '/api/hsapp/iframe/properties/deals',
  PROPS_TICKETS: '/api/hsapp/iframe/properties/tickets',
  POST_PROPS: '/api/hsapp/iframe/update/properties',
  POST_ENGAGEMENTS: '/api/hsapp/iframe/update/engagements',
  POST_EDITPROPS: '/api/hsapp/iframe/update/editable-properties',
  USER_ASSOCIATION: '/api/hsapp/iframe/users-associations',
  SETUP: '/api/hsapp/iframe/setup',
  PHONE: '/api/hsapp/iframe/account/update/phone-number',
};

export const defaultQueryParams = {
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchInterval: false,
};
