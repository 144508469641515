//Functional Libs
import { useParams, useNavigate } from 'react-router-dom';
import { Apps } from '../data/hubspotApps';
//Lib Components
import Container from 'react-bootstrap/Container';
//Constants
import {
  BILLINGSUCCESS_PAGE_SUBTITLE,
  BILLINGSUCCESS_PAGE_TITLE,
} from '../utils/BillingPage/constants';
import Button from '../components/Button';
import { ACTION, axiosInstance } from '../api/api';
import { useEffect, useState } from 'react';

const BillingPageSuccessPage = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ counter, setCounter ] = useState(0);
  const { app_id, subscription_products } = useParams();

  const navigate = useNavigate();

  const app_selected = Apps[app_id];
  const handleBackToSettings = () => {
    navigate(`/${app_id}/settings`);
  }

  useEffect(() => {
    axiosInstance
      .get(ACTION.SETTINGS, { withCredentials: true })
      .then((res) => {
        if (res.data?.subscription_products === subscription_products) {
          setIsLoading(false);
        } else {
          setTimeout(() => {
            setCounter(counter + 1);
          }, 2000);
        }
      })
  }, [counter, subscription_products]);

  return (
    <Container>
      <div
        className={`billing-top-container ${app_selected?.appName
          ?.toLowerCase()
          .replace(/ /g, '-')}`}
      >
        <h3 className="billing-page-title">{BILLINGSUCCESS_PAGE_TITLE}</h3>
        <p>{BILLINGSUCCESS_PAGE_SUBTITLE}</p>
        <Button
          text="My Plan"
          onClick={() => handleBackToSettings()}
          isLoading={isLoading}
        />
      </div>
    </Container>
  );
};

export default BillingPageSuccessPage;
