//LABELS & TEXT MESSAGES
export const PREV_PAGE = -1;
export const PREV_PAGE_LABEL_TEXT = '< back';
export const BILLING_PAGE_TITLE = 'Insert your billing information';
export const BILLING_PAGE_SUBTITLE =
  'These are the information that we will use to send your invoice';
  export const BILLINGSUCCESS_PAGE_TITLE = 'Wait for the payment to be completed';
  export const BILLINGSUCCESS_PAGE_SUBTITLE = 'In a few minutes your plan will be updated.';
export const INVOICE_SUBMIT_SUCCESS = 'Invoice form submitted successfully!';
export const INVOICE_SUBMIT_FAIL =
  'There has been an error while submitting your data, please try again later!';
export const INVOICE_GET_FAIL =
  'There has been an error while getting your invoice data, please try again later!';
export const MODIFY_BILLING_PAGE_TITLE = 'Modify your billing information';
export const MODIFY_BILLING_PAGE_SUBTITLE =
  'These are the information that we will use to send your invoice';

//VARIABLES & CONSTANTS
export const FORM_TYPE = {
  NEW: 'new',
  MODIFY: 'modify',
};

export const CLIENT_TYPE = {
  BUSINESS: 'Business',
  PRIVATE: 'Private',
};

export const COUNTRY_TYPES = {
  LOCAL: 'Local',
  UE: 'UE',
  EXTRA_UE: 'Extra UE',
};
export const INITIAL_FORM_STATUS = {
  clientType: CLIENT_TYPE.BUSINESS,
  country: '',
  country_it: '',
  countryType: '',
};
export const BILLING_FORM_LABELS = {
  CLIENT_TYPE: 'Client Type',
  COUNTRY: 'Country',
  CLIENT_NAME: 'Full name or Company name',
  TAX_DOMICILE: 'Tax domicile',
  ADDRESS: 'Legal address',
  CITY: 'City',
  POSTCODE: 'Postcode',
  DISTRICT: 'District',
  VAT: 'VAT',
  COUNTRY_CODE: 'Type here your VAT without the country prefix',
  TAX_CODE: 'Tax Code',
  PEC_SDI_CODE: 'PEC or SDI code',
  VIES: 'Check the box if subscribed to VIES',
  SUBMIT_BUTTON_TEXT: 'Proceed on Stripe',
  PAYMENT_BUTTON_TEXT: 'Pay and Change plan',
  MODIFY_BUTTON_TEXT: 'Modify',
};

export const BILLING_FORM_KEYS = {
  CLIENT_TYPE: 'client_type',
  COUNTRY: 'country',
  COUNTRY_TYPE: 'country_type',
  COUNTRY_IT: 'country_it',
  CLIENT_NAME: 'full_name_or_company_name',
  TAX_DOMICILE: 'tax_domicile',
  ADDRESS: 'legal_address',
  CITY: 'city',
  POSTCODE: 'postcode',
  DISTRICT: 'district',
  VAT: 'vat',
  TAX_CODE: 'tax_code',
  PEC_SDI_CODE: 'pec_sdi',
  VIES: 'vies',
};

export const DEFAULT_BILLING_FORM_DATA = {
  // client_type: '',
  // country: '',
  // country_type: '',
  // country_it: '',
  // full_name_or_company_name: '',
  // tax_domicile: '',
  // postcode: '',
  // district: '',
  vat: '',
  tax_code: '',
  pec_sdi: '',
  vies: '',
};

export const MESSAGE_MODAL_TEXTS = {
  TITLE: 'Warning!',
  MESSAGE: 'Changing the country/business type will clear the data filled.',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
};

export const COUNTRY_CODE = {
  Austria: 'AT',
  Belgio: 'BE',
  Bulgaria: 'BG',
  Cipro: 'CY',
  'Repubblica Ceca': 'CZ',
  Germania: 'DE',
  Danimarca: 'DK',
  Estonia: 'EE',
  Grecia: 'EL',
  Spagna: 'ES',
  Finlandia: 'FI',
  Francia: 'FR',
  Croazia: 'HR',
  Ungheria: 'HU',
  Irlanda: 'IE',
  Italia: 'IT',
  Lituania: 'LT',
  Lussemburgo: 'LU',
  Lettonia: 'LV',
  Malta: 'MT',
  'Paesi Bassi': 'NL',
  Polonia: 'PL',
  Portogallo: 'PT',
  Romania: 'RO',
  Svezia: 'SE',
  Slovenia: 'SI',
  Slovacchia: 'SK',
  'Irlanda del Nord': 'XI',
};
