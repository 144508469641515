import Spinner from './Spinner';
const Button = ({ onClick, text, type, disabled, extraClass, isLoading = false }) => {
  return (
    <button
      className={`exelab-btn ${extraClass ? extraClass : ''}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {isLoading ? <Spinner dimension={'20px'} /> : text}
    </button>
  );
};

export default Button;
